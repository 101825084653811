import store from '@/store'
import { toRaw } from 'vue'

export interface IChecklist {
  [key: string]: {
    action?: string,
    loader?: string
  }
}

export const checkAllIsLoaded = (checklist: IChecklist) => (
  Object.keys(checklist).every((key) => {
    if (checklist[key].loader) {
      return !!store.getters[checklist[key].loader || '']
    }

    return !!toRaw(store.getters[key])
  })
)

export const checkAllIsLoadedPromise = ((
  checklist: IChecklist,
): Promise<boolean> => new Promise((resolve) => {
  const waitForAll = setInterval(() => {
    const allGood = checkAllIsLoaded(checklist)
    if (!allGood) return

    clearInterval(waitForAll)
    resolve(true)
  }, 100)
}))

export const dispatchChecklist = (checklist: IChecklist): void => {
  Object.keys(checklist).forEach((key) => {
    if (checklist[key].action) {
      store.dispatch(checklist[key].action as string)
    }
  })
}

const resourcesV3Checklist: IChecklist = {
  fractionsV3: {
    action: 'getFractionsV3',
    loader: 'areFractionsLoadedV3',
  },
  materialsV3: {
    action: 'getMaterialsV3',
    loader: 'areMaterialsLoadedV3',
  },
  typesV3: {
    action: 'getTypesV3',
    loader: 'areTypesLoadedV3',
  },
  typeGroupsV3: {
    action: 'getTypeGroupsV3',
    loader: 'areTypeGroupsLoadedV3',
  },
  coatingsV3: {
    action: 'getCoatingsV3',
    loader: 'areCoatingTypesLoadedV3',
  },
  coatingTypesV3: {
    action: 'getCoatingTypesV3',
    loader: 'areCoatingsLoadedV3',
  },
  colorsV3: {
    action: 'getColorsV3',
    loader: 'areColorsLoadedV3',
  },
  itemsV3: {
    action: 'getItemsV3',
    loader: 'areItemsLoadedV3',
  },
  itemGroupsV3: {
    action: 'getItemGroupsV3',
    loader: 'areItemGroupsLoadedV3',
  },
  questionsV3: {
    action: 'getQuestionsV3',
    loader: 'areQuestionsLoadedV3',
  },
  groupQuestionsV3: {
    action: 'getGroupQuestionsV3',
    loader: 'areGroupQuestionsLoadedV3',
  },
  categoriesV3: {
    action: 'getCategoriesV3',
    loader: 'areCategoriesLoadedV3',
  },
  roomsV3: {
    action: 'getRoomsV3',
    loader: 'areRoomsLoadedV3',
  },
  tagsV3: {
    action: 'getTagsV3',
    loader: 'areTagsLoadedV3',
  },

  areasV3: {
    action: 'getAreasV3',
    loader: 'areAreasLoadedV3',
  },
  ewcCodesV3: {
    action: 'getEWCCodesV3',
    loader: 'areEWCCodesLoadedV3',
  },
  laboratoriesV3: {
    action: 'getLaboratoriesV3',
    loader: 'areLaboratoriesLoadedV3',
  },
  analysisDeliveryTimeOptionsV3: {
    action: 'getAnalysisDeliveryTimeOptionsV3',
    loader: 'areAnalysisDeliveryTimeOptionsLoadedV3',
  },
  sampleTypesV3: {
    action: 'getSampleTypesV3',
    loader: 'areSampleTypesLoadedV3',
  },
  analysisTestGroupsV3: {
    action: 'getAnalysisTestGroupsV3',
    loader: 'areAnalysisTestGroupsV3',
  },
  analysisTestsV3: {
    action: 'getAnalysisTestsV3',
    loader: 'areAnalysisTestsV3',
  },
  ecoToxAnalysisTestIDListV3: {
    action: 'getEcoToxAnalysisTestIDListV3',
    loader: 'areEcoToxAnalysisTestIDListV3',
  },
  pahV1AnalysisTestIDListV3: {
    action: 'getPAHV1AnalysisTestIDListV3',
    loader: 'arePAHV1AnalysisTestIDListV3',
  },
  projectTypesV3: {
    action: 'getProjectTypesV3',
    loader: 'areProjectTypesLoadedV3',
  },
  projectOptionsV3: {
    action: 'getProjectOptionsV3',
    loader: 'areProjectOptionsLoadedV3',
  },
}

export const baseChecklist: IChecklist = {
  ...resourcesV3Checklist,
  appIsInitialized: {},
  isLoggedIn: {},
  currentUser: {},
  hubAccounts: {
    action: 'hubStore/getAccounts',
    loader: 'hubStore/areAccountsLoaded',
  },
  accounts: {
    action: 'getAccounts',
    loader: 'areAccountsLoaded',
  },
  users: {
    action: 'getUsers',
    loader: 'areUsersLoaded',
  },
  languages: {
    action: 'getLanguages',
    loader: 'areLanguagesLoaded',
  },
  translationGroups: {
    action: 'getTranslationGroups',
    loader: 'areTranslationGroupsLoaded',
  },
  countries: {
    action: 'getCountries',
    loader: 'areCountriesLoaded',
  },
  areas: {
    action: 'getAreasV2',
    loader: 'areAreasLoadedV2',
  },
  sampleTypes: {
    action: 'getSampleTypesV2',
    loader: 'areSampleTypesLoadedV2',
  },
  analysisTests: {
    action: 'getAnalysisTestsV2',
    loader: 'areAnalysisTestsLoadedV2',
  },
  analysisTestGroups: {
    action: 'getAnalysisTestGroupsV2',
    loader: 'areAnalysisTestGroupsLoadedV2',
  },
  laboratories: {
    action: 'getLaboratoriesV2',
    loader: 'areLaboratoriesLoadedV2',
  },
  analysisDeliveryTimeOptions: {
    action: 'getAnalysisDeliveryTimeOptionsV2',
    loader: 'areAnalysisDeliveryTimeOptionsLoadedV2',
  },
  ecoToxAnalysisTestIDList: {
    action: 'getEcoToxAnalysisTestIDListV2',
    loader: 'areEcoToxListLoadedV2',
  },
  workDescriptionGroups: {
    action: 'getWorkDescriptionGroupsV2',
    loader: 'areWorkDescrioptionGroupsLoadedV2',
  },
  ewcCodes: {
    action: 'getEWCCodesV2',
    loader: 'areEWCCodesLoadedV2',
  },
  categories: {
    action: 'getCategoriesV2',
    loader: 'areCategoriesLoadedV2',
  },
  types: {
    action: 'getTypesV2',
    loader: 'areTypesLoadedV2',
  },
  categoryTypes: {
    action: 'getCategoryTypesV2',
    loader: 'areCategoryTypesLoadedV2',
  },
  materials: {
    action: 'getMaterialsV2',
    loader: 'areMaterialsLoadedV2',
  },
  fractions: {
    action: 'getFractionsV2',
    loader: 'areFractionsLoadedV2',
  },
  tags: {
    action: 'getTagsV2',
    loader: 'areTagsLoadedV2',
  },
  questions: {
    action: 'getQuestionsV2',
    loader: 'areQuestionsLoadedV2',
  },
  groupQuestions: {
    action: 'getGroupQuestionsV2',
    loader: 'areGroupQuestionsLoadedV2',
  },
  categoryTypeGroups: {
    action: 'getCategoryTypeGroupsV2',
    loader: 'areCategoryTypeGroupsLoadedV2',
  },
  coatingTypes: {
    action: 'getCoatingTypesV2',
    loader: 'areCoatingTypesLoadedV2',
  },
  customTypes: {
    action: 'getCustomTypesV2',
    loader: 'areReportsLoadedV2',
  },
  reusedTypes: {
    action: 'getReusedTypesV2',
    loader: 'areReportsLoadedV2',
  },
  customRooms: {
    action: 'getCustomRoomsV2',
    loader: 'areReportsLoadedV2',
  },
}
