import { createStore, Store as BaseStore } from 'vuex'
import { authStore } from './auth'
import { accountStore } from './accounts'
import { userStore } from './users'
import { translationStore } from './translations'
import { countriesStore } from './countries'
import { resourcesStoreV3 } from './resources-version-3'
import { resourcesStoreV2 } from './resources-version-2'
import { reportsStoreV2 } from './reports-version-2'
import { syncToDevStore } from './syncToDev'
import { hubStore } from './hub'
import { transferV2ToV3Resources } from './transfer-v2-to-v3-resources'

export interface IRootState {
  packageVersion: string
  isPageLoading: boolean
  selectedLanguage: string
}

export type Store = BaseStore<IRootState>

const store: Store = createStore<IRootState>({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
    isPageLoading: true,
    selectedLanguage: 'da-DK',
  },
  mutations: {
    updatePageLoadingStatus: (state, status) => {
      state.isPageLoading = status
    },
  },
  actions: {
    setPageLoadingStatus: ({ commit }, status) => {
      commit('updatePageLoadingStatus', status)
    },
  },
  getters: {
    packageVersion: (state) => state.packageVersion,
    isPageLoading: (state) => state.isPageLoading,
    selectedLanguage: (state) => state.selectedLanguage,
    environment: () => process.env.NODE_ENV,
  },
  modules: {
    hubStore,
    authStore,
    accountStore,
    userStore,
    translationStore,
    countriesStore,
    resourcesStoreV3,
    resourcesStoreV2,
    reportsStoreV2,
    syncToDevStore,
    transferV2ToV3Resources,
  },
})

export default store
