import { firebase } from '@/firebase'

export enum StatType {
  CREATED = 'created',
  STARTED = 'started',
  COMPLETED = 'completed',
  REQUISITIONSENT = 'requisitionSent',
  BILLABLE = 'billable',
}
export interface IStat {
  [StatType.CREATED]: number
  [StatType.STARTED]: number
  [StatType.COMPLETED]: number
  [StatType.REQUISITIONSENT]: number
  [StatType.BILLABLE]: number
}
export interface IStats {
  overallStats?: IStat
  monthlyStats?: {
    [monthYear: string]: IStat
  }
}

export interface IHubAccount {
  name: string
  id: string
  url: string
  logo: string
  createdAt: string
  isPrivate: true
  contactUserID: string
}
export interface IAccount extends IStats {
  name: string
  id: string
  baseCountry: string
  address: string
  postalCode: string
  city: string
  contactPerson: {
    name: string
    phoneNumber: string
    email: string
  }
  companyInitials: string
  nextScreeningNumber: number
  primaryLabID: string
  analysisDeliveryTimeOptionID: string
  euroFinsNumber: string
  hojvangNumber: string
  analysisReportEmail: string
  isActive: boolean
  useSamplingID: boolean
  useMandatoryManualCaseNumbers: boolean
  allowMilvaEuroFins: boolean
  billPerRequisition: boolean
  createdDate: firebase.firestore.Timestamp | number | undefined, // Needs to looked into
  images: {
    mainLogo: string
    emailLogo: string
  },
  statusHistory?: {
    state: boolean
    timestamp: firebase.firestore.Timestamp
  }[]
  allowedProjectTypeIDs: string[]
  autoSelectedProjectOptionIDs: string[]
}

export enum Role {
  SUPER_USER = 'super_user',
  SCREENER = 'screener',
  DEVELOPER = 'developer'
}
export enum RoleHub {
  SUPER_USER = 'super_user',
}
export interface IUserBase {
  id: string
  fullName: string
  initials: string
  email: string
  phoneNumber: string
  mustUpdatePassword: boolean
  isAdmin: boolean
  firstTimeLogin: boolean
}
export interface IUserHubData {
    accountID: string
    roles: RoleHub[] | []
    projects: string[]
    createdAt: string
    lastSeen: string
    isActive: boolean
  }
export interface IUserProData extends IStats {
  roles: Role[]
  accountID: string
  nextPersonalSamplingID: number
  lastSeen: firebase.firestore.Timestamp | number | undefined // Needs to looked into
  lastSeenV3: firebase.firestore.Timestamp | number | undefined // Needs to looked into
  expiryDate: string
  isActive: boolean
}
export interface IUserHub extends IUserBase {
  hub: IUserHubData
}

export interface IUserPro extends IUserBase, IUserProData {
}

export type TUser = IUserPro | IUserHub | IUserPro & IUserHub

